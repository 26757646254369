<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-layout wrap class="px-3">
      <v-row align="start" class="col-12">
        <h2
          class="text-h3  text-capitalize font-weight-black primary--text px-0"
        >
          {{ $t('faq') }}
        </h2>
      </v-row>
      <v-row class="col-12"> <v-divider /> </v-row>

      <v-row class="pa-xs-4 col-12">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="faq in cloneQuestions" :key="faq.id">
            <!-- eslint-disable vue/no-v-html -->
            <v-expansion-panel-header
              color="primary"
              class="white--text"
              v-html="faq.question"
            >
              <template v-slot:actions>
                <v-icon color="white">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-4" v-html="faq.answer" />
            <!-- eslint-enable -->
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-layout>
    <div class="text-center">
      <v-pagination
        v-if="pagination.total >= pagination.questionsPerPage"
        v-model="pagination.page"
        :length="pages"
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  data: () => ({
    pagination: {
      page: 1,
      total: 0,
      questionsPerPage: 10
    }
  }),
  computed: {
    ...mapGetters(['faqs']),
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.questionsPerPage)
    },
    cloneQuestions() {
      var clone = JSON.parse(JSON.stringify(this.faqs))
      var startFrom =
        this.pagination.page * this.pagination.questionsPerPage -
        this.pagination.questionsPerPage
      return clone.splice(startFrom, this.pagination.questionsPerPage)
    }
  },
  created() {
    this.getFAQs()
    this.pagination.total = this.faqs.length
  },
  methods: {
    ...mapActions(['getFAQs'])
  }
})
</script>
